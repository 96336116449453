<template>
  <svg class="intro" viewbox="0 0 200 86">
    <text
      text-anchor="start"
      x="30"
      y="30"
      class="text text-stroke"
      clip-path="url(#text1)"
    >
      ¡ANUNCIA TU NEGOCIO
    </text>
    <text
      text-anchor="start"
      x="30"
      y="60"
      class="text text-stroke"
      clip-path="url(#text2)"
    >
      EN EL PRÓXIMO
    </text>
    <text
      text-anchor="start"
      x="30"
      y="90"
      class="text text-stroke"
      clip-path="url(#text3)"
    >
      EJEMPLAR!
    </text>
    <text
      text-anchor="start"
      x="30"
      y="30"
      class="text text-stroke text-stroke-2"
      clip-path="url(#text1)"
    >
      ¡ANUNCIA TU NEGOCIO
    </text>
    <text
      text-anchor="start"
      x="30"
      y="60"
      class="text text-stroke text-stroke-2"
      clip-path="url(#text2)"
    >
      EN NUESTRO
    </text>
    <text
      text-anchor="start"
      x="30"
      y="90"
      class="text text-stroke text-stroke-2"
      clip-path="url(#text3)"
    >
      PRÓXIMO EJEMPLAR!
    </text>
    <defs>
      <clipPath id="text1">
        <text text-anchor="start" x="30" y="30" class="text">¡ANUNCIA TU NEGOCIO</text>
      </clipPath>
      <clipPath id="text2">
        <text text-anchor="start" x="30" y="60" class="text">EN NUESTRO</text>
      </clipPath>
      <clipPath id="text3">
        <text text-anchor="start" x="30" y="90" class="text">PRÓXIMO EJEMPLAR!</text>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
  let intervalFunction = ''
  $(function () {
    $('.intro').addClass('go')

    intervalFunction = setInterval(() => {
      $('.intro')
        .removeClass('go')
        .delay(200)
        .queue(function (next) {
          $('.intro').addClass('go')
          next()
        })
    }, 20000)
  })
  export default {
    name: 'AnimationText1',
    beforeDestroy() {
      clearInterval(intervalFunction)
    },
  }
</script>
<style lang="scss" scoped>
  svg.intro {
    background: linear-gradient(135deg, #fff, #fff);
    width: 500px;
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);*/
    box-shadow: 0 30px 50px -20px rgb(46, 6, 66);

    .text {
      display: none;
    }
    &.go {
      .text {
        font-family: Arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.7;
        text-transform: uppercase;
        display: block;
        color: #5b5b5e;
      }
      .text-stroke {
        fill: none;
        stroke: #5b5b5e;
        stroke-width: 12px;
        stroke-dashoffset: -900;
        stroke-dasharray: 900;
        stroke-linecap: butt;
        stroke-linejoin: round;
        animation: dash 2.5s ease-in-out forwards;
      }
      .text-stroke:nth-child(2) {
        animation-delay: 0.3s;
      }
      .text-stroke:nth-child(3) {
        animation-delay: 0.9s;
      }
      .text-stroke-2 {
        stroke: #003a56;
        animation-delay: 1.2s;
      }
      .text-stroke:nth-child(5) {
        animation-delay: 1.5s;
      }
      .text-stroke:nth-child(6) {
        animation-delay: 1.8s;
      }
    }
  }

  @keyframes dash {
    100% {
      stroke-dashoffset: 0;
    }
  }
</style>
